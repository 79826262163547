<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Edit Form Pemesanan
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <input name="ctrluser_id"  ref="ctrluser_id" v-model="formData.user_id" type="hidden" />
                                        <input name="ctrlkode_produk"  ref="ctrlkode_produk" v-model="formData.kode_produk" type="hidden" />
                                        <input name="ctrlnama_produk"  ref="ctrlnama_produk" v-model="formData.nama_produk" type="hidden" />
                                        <input name="ctrlharga"  ref="ctrlharga" v-model="formData.harga" type="hidden" />
                                        <input name="ctrlstok_tersedia"  ref="ctrlstok_tersedia" v-model="formData.stok_tersedia" type="hidden" />
                                        <div class="md:col-12 col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Qty *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlqty" v-model.trim="formData.qty"  label="Qty" type="number" placeholder="Jumlah Pesanan"   step="any"    
                                                    class=" w-full" :class="getErrorClass('qty')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('qty')" class="p-error">{{ getFieldError('qty') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlstatus_pembayaran"  ref="ctrlstatus_pembayaran" v-model="formData.status_pembayaran" type="hidden" />
                                        <input name="ctrlfoto_produk"  ref="ctrlfoto_produk" v-model="formData.foto_produk" type="hidden" />
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Jenis Pengiriman *
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <api-data-source   api-path="components_data/jenis_pengiriman_option_list" >
                                                        <template v-slot="req">
                                                            <Dropdown  class="w-full" :class="getErrorClass('jenis_pengiriman')"   :loading="req.loading"   optionLabel="label" optionValue="value" ref="ctrljenis_pengiriman"  v-model="formData.jenis_pengiriman" :options="req.response" label="Jenis Pengiriman"  placeholder="Jenis Pengiriman" >
                                                            </Dropdown> 
                                                            <small v-if="isFieldValid('jenis_pengiriman')" class="p-error">{{ getFieldError('jenis_pengiriman') }}</small> 
                                                        </template>
                                                    </api-data-source>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Biaya Pengiriman 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlbiaya_pengiriman" v-model.trim="formData.biaya_pengiriman"  label="Biaya Pengiriman" type="text" placeholder="Biaya Pengiriman"    readonly  
                                                    class=" w-full" :class="getErrorClass('biaya_pengiriman')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('biaya_pengiriman')" class="p-error">{{ getFieldError('biaya_pengiriman') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <input name="ctrlitem_id"  ref="ctrlitem_id" v-model="formData.item_id" type="hidden" />
                                    </div>
                                    <!--[form-content-end]-->
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="loading">
            <div style="min-height:200px" class="flex gap-3 justify-content-center align-items-center p-3">
                <div><ProgressSpinner style="width:50px;height:50px" /> </div>
                <div class="text-500">Loading... </div>
            </div>
        </template>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required, numeric, } from 'src/services/validators';
	import { utils } from 'src/utils';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useEditPage } from 'src/composables/editpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		id: [String, Number],
		pageStoreKey: {
			type: String,
			default: 'FORM_PEMESANAN',
		},
		pageName: {
			type: String,
			default: 'form_pemesanan',
		},
		routeName: {
			type: String,
			default: 'form_pemesananedit',
		},
		pagePath: {
			type : String,
			default : 'form_pemesanan/edit',
		},
		apiPath: {
			type: String,
			default: 'form_pemesanan/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: "Update Record",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Data berhasil diupdate",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	const auth = useAuth();
	
	const formDefaultValues = Object.assign({
		user_id: auth.userId, 
		kode_produk: "", 
		nama_produk: "", 
		harga: "", 
		stok_tersedia: "", 
		qty: "1", 
		status_pembayaran: "Keranjang", 
		foto_produk: "", 
		jenis_pengiriman: "", 
		biaya_pengiriman: "", 
		item_id: utils.randomStr(12), 
	}, props.pageData);
	
	const formData = reactive({ ...formDefaultValues });
	
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/form_pemesanan`);
		}
	}
	
	// form validation rules
	const rules = computed(() => {
		return {
			user_id: {  },
			kode_produk: {  },
			nama_produk: {  },
			harga: {  },
			stok_tersedia: {  },
			qty: { required, numeric },
			status_pembayaran: {  },
			foto_produk: {  },
			jenis_pengiriman: { required },
			biaya_pengiriman: {  },
			item_id: {  }
		}
	});
	
	const page = useEditPage({store, props, formData, rules, afterSubmit });
	
	const {  currentRecord: editRecord } = toRefs(store.state);
	const {  pageReady, saving, loading, } = toRefs(page.state);
	
	const { apiUrl } = page.computedProps;
	
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Edit Form Pemesanan";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
</script>
<style scoped>
</style>
